
import axios from "axios";

const instance = axios.create({
});

const pageCache = {};

export default {


    async getCollection(contentType) {
        if ((contentType in pageCache)) {
            return pageCache[contentType];
        }
        try {
            //var json = (await import(/* webpackMode: "eager" */ `../../public/static/cms/${contentType}.json`)).default;
            //console.log('////', json);
            //return json.data;
            var response = await instance.get(`/static/cms/${contentType}.json`);
            var responseData = response.data;
            pageCache[contentType] = responseData.data;
            return responseData.data;
        }
        catch (e) {
            //console.log('////', e);
            return null;
        }
    },
    async getCollectionByCategory(categorySlug) {
        var categories = await this.getCollection('categories');
        var category = categories.find(x => x.slug == categorySlug || x.title == categorySlug);
        var blogs = category && category.blog_posts;
        var allBlogs = await this.getCollection('blog-posts');
        for (var blog of blogs) {
            var fullBlog = this.findItem(allBlogs, blog.slug);
            for (var key of Object.keys(fullBlog)) {
                blog[key] = fullBlog[key];
            }
            //console.log('////', blog.slug, fullBlog);
        }
        return blogs;
    },
    async getItem(contentType, slug) {
        var items = await this.getCollection(contentType);
        return this.findItem(items, slug);
    },
    findItem(items, slug) {
        var slugLowered = slug.toLowerCase();
        if (items) {
            var item = items.find(x => x.slug == slug || x.slug.toLowerCase() == slugLowered);
            return item;
        }
        return null;
    },
    async getItemByPath(contentType, path) {
        var items = await this.getCollection(contentType);
        if (items) {
            var item = items.find(x => x.path == path);
            return item;
        }
        return null;
    },
}