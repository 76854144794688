<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card v-if="article">
      <v-img v-if="article.img"
        :src="article.img"
        class="white--text align-end"
        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" 
        height="200px"
      >
        <v-card-title class="display-2" v-text="article.title"></v-card-title>
      </v-img>
      <v-card-title v-else class="display-2" v-text="article.title"></v-card-title>
      <v-card-text>
        <p class="text-muted pb-0">Posted on {{article.created}}</p>
        <v-alert v-if="tenant.isDefault" type="success" prominent outlined border="left">
          Sodisp is the most complete and engaging platform to host virtual events and challenges. 
          The only platform with Strava, Garmin and Polar auto-sync. 
          Start your virtual event or challenge today on sodisp.com!
          <br/>
          <v-btn color="success" outlined class="mt-4" to="/features">Explore all features</v-btn>
        </v-alert>
        <v-alert v-if="false && tenant.id === 'cofi'" type="success" prominent tile>
          CorporateFitness.app is the most connected platform to host corporate fitness and wellbeing events. 
          <br/>
          <v-btn color="white" outlined class="mt-4" to="/features">Explore all features</v-btn>
        </v-alert>
        <div v-if="article.img" class="float-right " style="max-width: 100%;">
        <v-img
          :src="article.img"
          class="ma-4"
          style="max-width: 500px"
        />
        </div>
        <!-- <div v-html="article.description" class="markdown pb-4 dynamic-content black--text font-weight-bold"> </div> -->
        <div v-if="article.content" v-html="article.content" class="markdown pb-4 dynamic-content black--text"> </div>
        <vue-markdown v-if="article.markdown" class="markdown mb-4 dynamic-content black--text" :html="false" :source="article.markdown" />

        <br class="clear" />

        <v-row dense>
          <v-col cols="12" sm="6" v-if="article.previous">
            <v-card :to="{name: 'blogPost', params: { id: article.previous }}">
              <v-img v-if="article.previous_img"
                :src="article.previous_img"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.3), rgba(0,0,0,.8)" 
                height="200px"
              >
                <v-card-title class="headline">
                  <i class="fa fa-arrow-left mr-2"/> Previous: <br/>
                  {{article.previous_title}}
                </v-card-title>
              </v-img>
              <v-card-title v-else class="headline">
                <i class="fa fa-arrow-left mr-2" /> Previous: <br/>
                {{article.previous_title}}
              </v-card-title>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" v-if="article.next">
            <v-card :to="{name: 'blogPost', params: { id: article.next }}">
              <v-img v-if="article.next_img"
                :src="article.next_img"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.3), rgba(0,0,0,.8)" 
                height="200px"
              >
                <v-card-title class="headline">
                  <i class="fa fa-arrow-right mr-2"/> Next: <br/>
                  {{article.next_title}}
                </v-card-title>
              </v-img>
              <v-card-title v-else class="headline">
                <i class="fa fa-arrow-right mr-2"/> Next: <br/>
                {{article.next_title}}
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider/>
      <v-card-text>
        <h3 class="subtitle">Questions or feedback?</h3>
        <p v-if="tenant.isDefault">
          Contact us (or request a demo) at <a href="mailto:hello@sodisp.com">hello@sodisp.com</a> to learn how we can support you with your event.
        </p>
        <p class="mb-0 mt-4">
          <v-btn class="mb-4 mr-4" color="primary" outlined to="/contact">Contact Us</v-btn>
          <v-btn class="mb-4" color="primary" text to="/request-demo">Request Demo</v-btn>
        </p>

      </v-card-text>
      <v-divider/>
      <iframe v-if="tenant.isDefault" src="https://cdn.forms-content.sg-form.com/bad6a860-8ea4-11ea-90d0-222d5e1f3e65" style="width:100%;border:0; height:400px;" noborder border="0" />

      <v-alert v-if="tenant.id === 'cofi'" type="info" prominent tile>
        <h3 class="subtitle">Ready to try it out?</h3>
        <p>
          Start a free 14-day trial with just 3 clicks!
        </p>
        <p class="mb-0 mt-4">
          <v-btn class="mb-4 mr-4" color="white" outlined to="/start-trial">Start Free Trial</v-btn>
        </p>
      </v-alert>

    </v-card>
  </div>
</template>

<script>

import articleService from "@/services/articleService";
import VueMarkdown from '@/components/VueMarkdown.vue'
import cmsService from "@/services/cmsService";
import { EventBus } from '@/plugins/eventbus.js';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "BlogPost",
  components: {
    VueMarkdown,
  },
  props: {
  },
  data: function() {
    return {
      tenant: tenant,
      article: null,
    };
  },
  created() {
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {

      if (tenant.id == 'cofi') {
        var blogpost = await cmsService.getItem('blog-posts', this.$route.params.id);
        // map to previous shape (temp)
        this.article = {
          title: blogpost.title,
          slug: blogpost.slug,
          img: blogpost.image,
          thumbnail: blogpost.thumbnail,
          created: this.$options.filters.localDate(blogpost.date, 'ddd L'),
          markdown: blogpost.content,
          next: blogpost.next ? blogpost.next.slug : null,
          next_img: blogpost.next ? blogpost.next.image : null,
          next_title: blogpost.next ? blogpost.next.title : null,
          previous: blogpost.prev ? blogpost.prev.slug : null,
          previous_img: blogpost.prev ? blogpost.prev.image : null,
          previous_title: blogpost.prev ? blogpost.prev.title : null,
        };
      }
      else {
        this.article = await articleService.get(`${tenant.blogCategorySlug}/${this.$route.params.id}`);
      }
      if (this.article == null) {
        // todo: redirect to 404
        return;
      }
      this.$nextTick(() => {
        // when content is loaded
      });
      EventBus.$emit('page-header-change', { title: this.article.title, description: this.article.description, image: this.article.img} );
    }
  },
  watch: {
    '$route.params.id': function (id) {
      this.loadData()
    }
  },
  computed: {
    breadcrumbItems() {
      return !this.article ? [] : [
        { text: 'Blog', exact: true, to: { name: 'blog' }},
        { text: this.article.title, disabled: true },
      ];
    },
  },


};
</script>

<style scoped>

</style>